// @flow
import React, {createContext, useReducer, type Node} from 'react';

import {type Store} from 'store/types';

export const StoreContext = createContext<?Store>();

export default function StoreProvider({
  reducer,
  initialState,
  children,
}: {
  reducer: (Object, Object) => Object,
  initialState: Object,
  children: Node,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{state, dispatch}}>
      {children}
    </StoreContext.Provider>
  );
}
