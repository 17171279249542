// @flow
import React, {PureComponent, type AbstractComponent} from 'react';

import {StoreContext} from 'store/StoreProvider';
import {type Store} from 'store/types';

export default function withStore<Config>(
  C: AbstractComponent<{|...Config, ...Store|}>,
): AbstractComponent<Config> {
  return class Wrapped extends PureComponent<*> {
    static contextType = StoreContext;

    render() {
      return <C {...this.props} store={this.context} />;
    }
  };
}
