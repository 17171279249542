// @flow

import {
  type EventFields,
  type ExceptionFields,
  default as GoogleAnalytics,
  type PageViewFields,
  type SetFields,
} from 'analytics/GoogleAnalytics';

export type Options = {|
  gaTrackingID: string,
  basename?: string,
|};

export type PageView = {|
  path: string,
  title?: string,
|};

export type Redirect = PageView;

export type Event = {|
  category: string,
  action: string,
  label?: string,
  value?: number,
  // page title
  title?: string,
  nonInteraction?: boolean,
|};

export type Exception = {|
  description: string,
  message?: string,
  fatal?: boolean,
|};

export default class Analytics {
  basename: string;
  googleAnalytics: GoogleAnalytics;

  constructor({gaTrackingID, basename = ''}: Options) {
    this.basename = basename;
    this.googleAnalytics = new GoogleAnalytics();

    this.googleAnalytics.create({
      trackingId: gaTrackingID,
      cookieDomain: 'auto',
    });

    if (process.env.NODE_ENV !== 'production') {
      this.googleAnalytics.set({sendHitTask: null});
    }
  }

  pageView(pageView: PageView) {
    const {path, title} = pageView;
    const page = this.basename + path;
    const gaSetFields: SetFields = {page};
    const gaSendFields: PageViewFields = {hitType: 'pageview', page};

    if (title) {
      gaSendFields.title = title;
    }

    this.googleAnalytics.set(gaSetFields);
    this.googleAnalytics.send(gaSendFields);
  }

  redirect({path, title}: Redirect) {
    const page = this.basename + path;
    this.event({
      category: 'navigation',
      action: 'redirect',
      label: page,
      title,
      nonInteraction: true,
    });
    this.googleAnalytics.set({page});
  }

  event({category, action, label, value, title, nonInteraction}: Event) {
    const gaFields: EventFields = {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
    };

    if (title) {
      gaFields.title = title;
    }

    if (label) {
      gaFields.eventLabel = label;
    }

    if (typeof value === 'number') {
      gaFields.eventValue = value;
    }

    if (typeof nonInteraction === 'boolean') {
      gaFields.nonInteraction = nonInteraction;
    }

    this.googleAnalytics.send(gaFields);
    // todo: send event to our backend
  }

  // never set dynamic stuff like `error.message` as `description` since
  // we'll get in trouble for sending PII to Google Analytics
  exception({description, fatal, message}: Exception) {
    const gaFields: ExceptionFields = {
      hitType: 'exception',
      exDescription: description,
    };

    if (typeof fatal === 'boolean') {
      gaFields.exFatal = fatal;
    }

    this.googleAnalytics.send(gaFields);
    // todo: send exception with message to our backend
  }

  // this can be called after other tracking events have fired since we
  // have session unification turned on in GA:
  // https://support.google.com/analytics/answer/4574780
  identify({customerID, email}: {customerID: string, email: string}) {
    if (typeof window.FULLSTORY !== 'undefined') {
      window.FULLSTORY.identify(customerID);
    }

    this.googleAnalytics.set({userId: customerID});

    this.event({
      category: 'user',
      action: 'setID',
      label: customerID,
      nonInteraction: true,
    });
  }
}
