// @flow
import {PureComponent, type Node} from 'react';
import {withRouter, type Router} from 'next/router';

import withAnalytics from 'analytics/withAnalytics';
import Analytics from 'analytics/Analytics';

type Props = {
  children?: Node,
  // injected
  analytics: Analytics,
  router: Router,
};

class NextPageViewTracker extends PureComponent<Props> {
  componentDidMount() {
    const {router} = this.props;
    router.events.on('routeChangeComplete', this.handleRouteChange);
    router.events.on('hashChangeComplete', this.handleHashChange);
    this.pageView(document.location, document.title);
  }

  componentWillUnmount() {
    const {router} = this.props;
    router.events.off('routeChangeComplete', this.handleRouteChange);
    router.events.off('hashChangeComplete', this.handleHashChange);
  }

  handleRouteChange = () => {
    this.pageView(document.location, document.title);
  };

  handleHashChange = () => {
    this.pageView(document.location, document.title);
  };

  pageView(location: Location, title: string) {
    const {analytics} = this.props;
    const path = location.pathname + location.hash + location.search;
    analytics.pageView({path, title});
  }

  render() {
    return this.props.children || null;
  }
}

// withRouter has bad typings...
export default (withAnalytics(withRouter((NextPageViewTracker: any))): any);
