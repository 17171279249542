// @flow
export const gaScript = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'UA-80988864-1');
`.replace(/\n/g, '');

export const fullstoryScript = `
window['_fs_debug'] = false;
window['_fs_host'] = 'fullstory.com';
window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
window['_fs_org'] = 'CJ88T';
window['_fs_namespace'] = 'FS';
(function (m, n, e, t, l, o, g, y) {
  if (e in m) { if (m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'); } return; }
  g = m[e] = function (a, b, s) { g.q ? g.q.push([a, b, s]) : g._api(a, b, s); }; g.q = [];
  o = n.createElement(t); o.async = 1; o.crossOrigin = 'anonymous'; o.src = 'https://' + _fs_script;
  y = n.getElementsByTagName(t)[0]; y.parentNode.insertBefore(o, y);
  g.identify = function (i, v, s) { g(l, { uid: i }, s); if (v) g(l, v, s) }; g.setUserVars = function (v, s) { g(l, v, s) }; g.event = function (i, v, s) { g('event', { n: i, p: v }, s) };
  g.shutdown = function () { g("rec", !1) }; g.restart = function () { g("rec", !0) };
  g.log = function (a, b) { g("log", [a, b]) };
  g.consent = function (a) { g("consent", !arguments.length || a) };
  g.identifyAccount = function (i, v) { o = 'account'; v = v || {}; v.acctId = i; g(o, v) };
  g.clearUserCookie = function () { };
  g._w = {}; y = 'XMLHttpRequest'; g._w[y] = m[y]; y = 'fetch'; g._w[y] = m[y];
  if (m[y]) m[y] = function () { return g._w[y].apply(this, arguments) };
})(window, document, window['_fs_namespace'], 'script', 'user');
`.replace(/\n/g, '');

export const gtagScript = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TWSQKPL');
`.replace(/\n/g, '');
