//@flow
import type Analytics from './Analytics';

export default () =>
  (({
    pageView() {},
    redirect() {},
    event() {},
    exception() {},
    identify() {},
  }: any): Analytics);
