// @flow

// add more as needed:
// https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
export type CreateFields = {|
  trackingId?: string,
  cookieDomain?: string,
|};

export type SetFields = {|
  page?: string,
  sendHitTask?: ?Function,
  userId?: string,
|};

export type HitType =
  | 'pageview'
  | 'screenview'
  | 'event'
  | 'transaction'
  | 'item'
  | 'social'
  | 'exception'
  | 'timing';

export type HitFields = {|
  hitType: HitType,
  title?: string,
  nonInteraction?: boolean,
|};

export type PageViewFields = {|
  ...HitFields,
  hitType: 'pageview',
  page: string,
|};

export type EventFields = {|
  ...HitFields,
  hitType: 'event',
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
  eventValue?: number,
|};

export type ExceptionFields = {|
  ...HitFields,
  hitType: 'exception',
  exDescription?: string,
  exFatal?: boolean,
|};

export type SendFields = PageViewFields | EventFields | ExceptionFields;
export type Fields = CreateFields | SetFields | SendFields;

export type Tracker = {|
  get(fieldName: string): any,
  set(fields: Fields): void,
  send(hitType: HitType, fields: Fields): void,
|};

export type ReadyCallback = Tracker => void;
export type Command = 'create' | 'send' | 'set';
export type FirstArg = Command | ReadyCallback;
export type SecondArg = Fields | void;
export type GA = (FirstArg, SecondArg) => void;
export type DummyGA = GA;

export default class GoogleAnalytics {
  ga: DummyGA | GA;

  constructor() {
    const dummyGA = (this.ga = loadGA());

    dummyGA(() => {
      // loadGA creates a dummy window.ga placeholder that is later overwritten
      // by a real window.ga when the analytics.js script loads, so we need to
      // get a new reference to the real thing here in the ReadyCallback
      this.ga = window.ga;
    });
  }

  create(fields: CreateFields) {
    this.ga('create', fields);
  }

  set(fields: SetFields) {
    this.ga('set', fields);
  }

  send(fields: SendFields) {
    this.ga('send', fields);
  }
}

// https://developers.google.com/analytics/devguides/collection/analyticsjs
function loadGA(): DummyGA {
  /* eslint-disable */
  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    // $FlowFixMe ignore
    a.async = 1;
    a.src = g;
    // $FlowFixMe ignore
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga',
  );
  /* eslint-enable */
  return window.ga;
}
