// @flow

import {type ContextRouter, type Location, withRouter} from 'react-router-dom';
import {PureComponent, type Node} from 'react';

import {
  type AnalyticsContext,
  AnalyticsContextTypes,
} from 'analytics/AnalyticsProvider';

type Props = {|
  ...ContextRouter,
  children?: Node,
|};

class PageViewTracker extends PureComponent<Props> {
  static contextTypes = AnalyticsContextTypes;

  context: AnalyticsContext;

  componentDidMount() {
    this.pageView(this.props.location, document.title);
  }

  componentDidUpdate(prev: Props) {
    const {location, history} = this.props;

    if (prev.location !== location) {
      switch (history.action) {
        case 'REPLACE':
          this.redirect(location, document.title);
          break;
        case 'PUSH':
        case 'POP':
        default:
          this.pageView(location, document.title);
          break;
      }
    }
  }

  pageView(location: Location, title: string) {
    const path = location.pathname + location.search;
    this.context.analytics.pageView({path, title});
  }

  redirect(location: Location, title: string) {
    const path = location.pathname + location.search;
    this.context.analytics.redirect({path, title});
  }

  render() {
    return this.props.children || null;
  }
}

export default withRouter(PageViewTracker);
