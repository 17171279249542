// @flow
import {PureComponent, type Node} from 'react';
import PropTypes from 'prop-types';

import {type Logger} from 'log/types';

export type LoggerContext = {
  logger: Logger,
};

export const LoggerContextTypes = {
  logger: PropTypes.object,
};

type Props = {|
  logger: Logger,
  children: Node,
|};

export default class LoggerProvider extends PureComponent<Props> {
  static childContextTypes = LoggerContextTypes;

  getChildContext() {
    return {logger: this.props.logger};
  }

  render() {
    const {children} = this.props;

    return children;
  }
}
