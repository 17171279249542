// @flow
import React, {PureComponent, type AbstractComponent} from 'react';
import {
  type AnalyticsContext,
  AnalyticsContextTypes,
} from 'analytics/AnalyticsProvider';
import Analytics from 'analytics/Analytics';

type InjectedProps = {|analytics: Analytics|};

export default function withAnalytics<Config>(
  C: AbstractComponent<{|...Config, ...InjectedProps|}>,
): AbstractComponent<Config> {
  return class Wrapped extends PureComponent<*> {
    static contextTypes = AnalyticsContextTypes;
    context: AnalyticsContext;

    render() {
      const {analytics} = this.context;
      return <C {...this.props} analytics={analytics} />;
    }
  };
}
