// @flow

import {type Node, PureComponent} from 'react';
import PropTypes from 'prop-types';

import Analytics from 'analytics/Analytics';

type Props = {|
  analytics: Analytics,
  children: Node,
|};

export type AnalyticsContext = {
  analytics: Analytics,
};

export const AnalyticsContextTypes = {
  analytics: PropTypes.object,
};

export default class AnalyticsProvider extends PureComponent<Props> {
  static childContextTypes = AnalyticsContextTypes;

  getChildContext() {
    const {analytics} = this.props;
    return {analytics};
  }

  render() {
    return this.props.children;
  }
}
