// @flow
import React, {PureComponent, type AbstractComponent} from 'react';

import {type LoggerContext, LoggerContextTypes} from './LoggerProvider';
import {type Logger} from './types';

type InjectedProps = {|logger: Logger|};

export default function withLogger<Config>(
  C: AbstractComponent<{|...Config, ...InjectedProps|}>,
): AbstractComponent<Config> {
  return class Wrapped extends PureComponent<*> {
    static contextTypes = LoggerContextTypes;
    context: LoggerContext;

    render() {
      const {logger} = this.context;
      return <C {...this.props} logger={logger} />;
    }
  };
}
